@import '../../styles/variables.module.scss';

.header {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $bgLightLight;
    border-bottom: 1px solid $dividerDark;
    z-index: 99;

    @extend .wrapped;

    .logo {
        height: 100%;
        display: flex;
        align-items: center;
        flex-shrink: 1;
        overflow: hidden;
        
        h1 {
            font-size: 1.4rem;
            font-weight: 500;
            text-transform: uppercase;
            color: $primaryMain;
            line-height: 100%;
            flex: 1;
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;
        }
    }

    .showNav {
        border-radius: $borderRadius;
        padding: $padding * 2;
        background: $primaryLightest;
        display: none;
        visibility: hidden;

        &:hover { background: $primaryLight; cursor: pointer; }
        &:active { background: $primaryMain; }
        
        @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { display: flex; visibility: visible; }
        @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) {  }
        @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) {  }
        @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) {  }
        @media (min-width: $breakpointsXl) {  }
    }

    .mainNav {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        top: auto;
        gap: $spacing * 4;

        .subNav {
            height: 100%;
            display: flex;
            align-items: center;
            position: relative;
            background: $bgLightLight;

            &:hover {
                .subNavChildren {
                    display: flex;
                    visibility: visible;
                }
            }

            .subNavParent {
                display: flex;
                align-items: center;
                gap: $spacing * 1;
                text-transform: uppercase;
                font-weight: $fontWeightSemiBold;
                font-size: 0.8rem;
                letter-spacing: 0.5px;

                &:hover {
                    cursor: pointer;
                    color: $primaryMain;

                    svg { polyline { stroke: $primaryMain; } }
                }
            }

            .subNavChildren {
                display: none;
                visibility: hidden;
                position: absolute;
                display: flex;
                flex-direction: column;
                gap: $spacing * 2;
                width: 240px;
                top: $spacing * 10;
                right: 0;
                padding: $padding * 2;
                background: $bgLightLight;
                border-radius: $borderRadius;
                border: 1px solid $dividerDark;

                .subNavLink {
                    width: 100%;
                    padding: $padding * 2;
                    border-radius: $borderRadius;
                    display: flex;
                    flex-direction: column;
                    gap: $spacing * 1;

                    &:hover { background: $primaryLightest; }

                    .pipex {
                        font-weight: 500;
                        color: #838485;

                        span {
                            font-weight: 500;
                            font-size: 1.2rem;
                            color: #ed1c24;
                        }
                    }

                    .desc {
                        font-size: 0.9rem;
                    }
                }
            }
        }

        .navLink {
            text-transform: uppercase;
            font-weight: $fontWeightSemiBold;
            font-size: 0.8rem;
            letter-spacing: 0.5px;
            background: $bgLightLight;
            border-radius: $borderRadius;
            display: flex;
            align-items: center;
            gap: $spacing * 2;

            &:hover {
                cursor: pointer;
                color: $primaryMain;
            }

            &.login {
                padding-top: $spacing * 2;
                padding-bottom: $spacing * 2;
                padding-left: $spacing * 2;
                padding-right: $spacing * 2;
                background: $primaryMain;
                color: $primaryContrastText;
                font-weight: 400;

                svg {
                    fill: $primaryContrastText;
                }

                &:hover {
                    background: $primaryDark;
                }
            }
        }

        @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) {
            width: 100%;
            max-width: 100%;
            height: auto;
            position: absolute;
            top: 60px;
            left: 0;
            flex-direction: column;
            overflow-x: hidden;
            gap: 0;
            display: none;
            visibility: hidden;

            &.show { display: flex; visibility: visible; }

            .subNav {
                width: 100%;
                height: auto;
                flex-direction: column;

                &:hover {
                    .subNavChildren {
                        display: none;
                        visibility: hidden;
                    }
                }

                .subNavParent {
                    width: 100%;
                    height: 40px;
                    border-bottom: 1px solid $dividerDark;
                    padding-left: $spacing * 4;
                    padding-right: $spacing * 4;
                    justify-content: space-between;

                    &:hover { background: $primaryLight; }
                    &:active { background: $primaryDark; color: $textLightest; }
                }

                .subNavChildren {
                    display: none;
                    visibility: hidden;
                    width: 100%;
                    position: relative;
                    top: 0;
                    left: 0;
                    gap: 0;
                    padding: 0;
                    border: 0;

                    &.show { display: flex; visibility: visible; }

                    .subNavLink {
                        padding-left: $padding * 12;
                        padding-right: $padding * 12;
                        border-radius: 0;
                        border-bottom: 1px solid $dividerDark;

                        .desc { display: none; visibility: hidden; }

                        &:hover { background: $primaryLight; }
                        &:active { background: $primaryDark; color: $textLightest; }
                    }
                }
            }

            .navLink {
                width: 100%;
                height: 40px;
                border-bottom: 1px solid $dividerDark;
                padding-left: $spacing * 4;
                padding-right: $spacing * 4;
                display: flex;
                align-items: center;
                border-radius: 0;

                &:hover { background: $primaryLight; }
                &:active { background: $primaryDark; color: $textLightest; }

                &.login {
                    padding-left: $spacing * 4;
                    padding-right: $spacing * 4;
                    background: $bgLightLight;
                    color: $textPrimary;

                    svg {
                        fill: $textPrimary;
                    }

                    &:hover { background: $primaryLight; color: $primaryDark; svg { fill: $primaryDark; }}
                }
            }
        }

        @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { }
        @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { }
        @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) { }
        @media (min-width: $breakpointsXl) { }
    }

}