@import '../../styles/variables.module.scss';

.contact {
    width: 100%;
    min-height: 100%;
    background-color: $bgLightMain;
    display: flex;
    align-items: center;
    gap: $spacing * 16;

    @extend .wrapped;

    .contactInfo {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $spacing * 8;
        position: relative;

        .dots {
            width: 150px;
            height: 150px;
            position: absolute;
            top: -50px;
            left: -50px;
            opacity: 75%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        h1 {
            font-size: 4rem;
            font-weight: 700;
            color: $primaryMain;
            text-shadow: 1px 1px 2px $shadowDark;
        }

        p {
            font-size: 1.8rem;
            line-height: 150%;
        }

        ul {
            display: flex;
            gap: $spacing * 8;

            li {
                display: flex;
                align-self: center;
                font-size: 1.2rem;
                gap: $spacing * 2;

                svg {
                    width: 18px;
                    height: 18px;
                    fill: $primaryMain;
                }

                a {
                    font-size: 1.2rem;

                    &:hover {
                        color: $primaryMain;
                    }
                }
            }
        }
    }

    .contactForm {
        width: 500px;
        padding: $spacing * 8;
        background: $bgLightLight;
        border: 1px solid $dividerDark;
        display: flex;
        flex-direction: column;
        gap: $spacing * 2;
        align-items: center;
        justify-content: center;

        .loading {
            display: flex;
            gap: $spacing * 4;
            align-items: center;
            justify-content: center;

            p {
                font-size: 1.4rem;
            }
        }

        .feedback {
            display: flex;
            gap: $spacing * 4;
            align-items: center;
            justify-content: center;

            &.success { svg { fill: $successLight; } }
            &.failed { svg { fill: $errorLight; } }

            svg {
                width: 40px;
                height: 40px;
            }

            p {
                font-size: 1.4rem;
            }
        }

        .inputContainer {
            width: 100%;
            border: 1px solid $dividerDark;
            display: flex;
            align-items: flex-start;

            &.error {
                border: 1px solid $errorLight;
            }

            svg {
                width: 40px;
                height: 40px;
                padding: $spacing * 2.5;
            }

            input[type='text'] {
                flex: 1;
                height: 40px;
                padding-left: $spacing * 2;
                padding-right: $spacing * 2;
                outline: none;
            }
    
            textarea {
                flex: 1;
                height: 120px;
                padding-top: $spacing * 2;
                padding-bottom: $spacing * 2;
                padding-left: $spacing * 2;
                padding-right: $spacing * 2;
                outline: none;
            }
        }

        button {
            width: 100px;
            height: 40px;
            padding-left: $padding * 4;
            padding-right: $padding * 4;
            background: $primaryMain;
            border-radius: $borderRadius;
            color: $primaryContrastText;
            font-size: 0.9rem;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: $fontWeightSemiBold;
            margin: 0 0 0 auto;
            
            &:hover {
                cursor: pointer;
                background: $primaryDark;
            }
        }
    }

    @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { 
        flex-direction: column;
        padding-top: $spacing * 10;
        padding-bottom: $spacing * 10;

        .contactInfo {
            align-items: center;

            .dots { display: none; }

            h1 {
                font-size: 3rem;
            }

            p {
                font-size: 1.4rem;
                text-align: center;
                padding-left: $spacing * 4;
                padding-right: $spacing * 4;
            }

            ul {
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        .contactForm {
            width: 100%;
            padding: $spacing * 4;
        }
    }

    @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { 
        flex-direction: column;
        padding-top: $spacing * 10;
        padding-bottom: $spacing * 10;

        .contactInfo {
            align-items: center;

            .dots { display: none; }

            h1 {
                font-size: 3.4rem;
            }


            p {
                font-size: 1.6rem;
                text-align: center;
                padding-left: $spacing * 10;
                padding-right: $spacing * 10;
            }
        }

        .contactForm {
            padding: $spacing * 6;
        }
    }

    @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { 
        flex-direction: column;
        padding-top: $spacing * 10;
        padding-bottom: $spacing * 10;

        .contactInfo {
            align-items: center;
            
            .dots { display: none; }

            p {
                text-align: center;
                padding-left: $spacing * 10;
                padding-right: $spacing * 10;
            }
        }
     }
}


@keyframes fadeIn {
    from { transform: scale(0.75); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
}