@import './variables.module.scss';

@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-Black.ttf'); font-weight: 800; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-ExtraBold.ttf'); font-weight: 700; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-Bold.ttf'); font-weight: 600; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-SemiBold.ttf'); font-weight: 500; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-Medium.ttf'); font-weight: 400; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-Regular.ttf'); font-weight: 300; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-Light.ttf'); font-weight: 200; }
@font-face { font-family: 'Mona'; src: url('../assets/fonts/mona/Mona-Sans-UltraLight.ttf'); font-weight: 100; }

* { 
	box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    border: 0;
    background: none;
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeightRegular;
    color: $textPrimary;
    flex-shrink: 0;
}

html, body {
    width: 100%;
    height: 100%;
	min-width: 100%;
	min-height: 100dvh;
    text-rendering: optimizeLegibility;
}

.main {
    background: $bgLightMain;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    .content {
        flex: 1;
        display: flex;
    }
}