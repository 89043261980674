@import '../../styles/variables.module.scss';

.footer {
    width: 100%;
    background: $bgDarkDark;
    padding-top: $padding * 10;
    padding-bottom: $padding * 10;
    display: flex;
    flex-direction: column;
    gap: $spacing * 4;

    @extend .wrapped;

    @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { gap: $spacing * 12; }
    @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { gap: $spacing * 12; }
    @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { gap: $spacing * 12; }

    .footerTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: $spacing * 4;

        .contact {
            display: flex;
            flex-wrap: wrap;
            gap: $spacing * 4;

            li {
                display: flex;
                align-items: center;
                gap: $spacing * 2;

                a {
                    color: $textLightest;

                    &:hover { color: $primaryMain; }
                }
                svg { fill: $textLightest; }

                &:hover {
                    cursor: pointer;
                    color: $primaryMain;

                    svg { fill: $primaryMain; }
                }
            }
        }

        .newsletter {
            width: 400px;
            height: 40px;
            padding: $padding * 1;
            display: flex;
            gap: $spacing * 2;
            background: $bgLightLight;
            border-radius: $borderRadius;

            input[type='text'] {
                min-width: 0;
                flex: 1;
                outline: 0;
                padding: $padding * 1;
            }

            button {
                height: 100%;
                padding-left: $padding * 4;
                padding-right: $padding * 4;
                background: $primaryMain;
                border-radius: $borderRadius;
                color: $primaryContrastText;
                font-size: 0.9rem;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                font-weight: $fontWeightSemiBold;

                &:hover {
                    cursor: pointer;
                    background: $primaryDark;
                }
            }
        }

        @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { 
            flex-direction: column;
            align-items: center;

            .contact { order: 2; justify-content: center; }
            .newsletter { order: 1; width: 100%; }
        }
        @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { 
            flex-direction: column;
            align-items: center;

            .contact { order: 2; justify-content: center; }
            .newsletter { order: 1; }
        }
        @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) {
            flex-direction: column;
            align-items: center;

            .contact { order: 2; justify-content: center; }
            .newsletter { order: 1; }
        }
    }

    .footerBottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: $spacing * 4;

        .copyright {
            color: $textLight;
        }

        .legalLinks {
            display: flex;
            gap: $spacing * 4;

            .legalLink {
                color: $textLight;

                &:hover  {
                    cursor: pointer;
                    color: $primaryMain;
                }
            }
        }

        @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) {
            flex-direction: column;
            align-items: center;

            .legalLinks { justify-content: center;}
        }
    }
}