@import '../../styles/variables.module.scss';

.subnav {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    gap: $spacing * 8;
    background: $bgLightLight;
    border-bottom: 1px solid $dividerDark;
    position: sticky;
    top: 0;
    font-weight: 400;
    overflow-x: scroll;
    scrollbar-width: none;
    z-index: 9;

    @extend .wrapped;

    @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { height: 60px; gap: $spacing * 4; }
    @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { height: 60px; gap: $spacing * 6; }

    &::-webkit-scrollbar { display: none; }

    li {
        &:hover { cursor: pointer; }
        &.active {
            font-weight: 600;
            color: $primaryMain;
        }
    }
}