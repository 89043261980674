@import '../../styles/variables.module.scss';

.home {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%), url('../../assets/images/grid.webp');
    animation: marquee 120s infinite linear;

    figure {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $spacing * 8;

        img {
            filter: drop-shadow(1px 1px 2px $shadowLight);
            
            text-shadow: 1px 1px 2px #000;

            @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { max-width: 120px; }
            @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { width: 160px; }
            @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { width: 180px; }
            @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) { width: 190px; }
            @media (min-width: $breakpointsXl) { width: 200px; }
        }

        figcaption {
            display: flex;
            flex-direction: column;
            gap: $spacing * 2;

            h1 {
                text-align: center;
                font-size: 5rem;
                text-transform: uppercase;
                font-weight: $fontWeightBold;
                color: $primaryMain;
                text-shadow: 1px 1px 2px $shadowDark;

                @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { font-size: 3rem; }
                @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { font-size: 3.8rem; }
                @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { font-size: 4.2rem; }
                @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) { font-size: 4.6rem; }
                @media (min-width: $breakpointsXl) { font-size: 5rem; }
            }

            span {
                display: flex;
                gap: $spacing * 2;
                justify-content: center;

                @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { flex-direction: column; gap: 0; h2 { font-size: 1.6rem; } }
                @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { h2 { font-size: 1.7rem; } }
                @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { h2 { font-size: 1.8rem; } }
                @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) { h2 { font-size: 1.9rem; } }
                @media (min-width: $breakpointsXl) { h2 { font-size: 2rem; }  }

                h2 { text-align: center; font-weight: $fontWeightSemiBold; }
                .animated { color: #38cdff; text-shadow: 1px 1px 2px $shadowLight; }
            }
        }
    }
}


@keyframes marquee {
    0% {  background-position: 0 0; }
    100% { background-position: -1190px -1190px; }
}
