//#region Colors

    //#region Main Colors
        $primaryLightest: #d0e2e9;
        $primaryLight: #d0e2e9;
        $primaryMain: #006385;
        $primaryDark: #004e68;
        $primaryDarkest: #002d3b;
        $primaryContrastText: #f2f2f2;

        $bgLightLight: #fff;
        $bgLightMain: #f6f8fa;
        $bgLightDark: #f2f2f2;

        $bgDarkLight: #3d3d3dc4;
        $bgDarkMain: #2e2e2ec4;
        $bgDarkDark: #1b1b1b;
    //#endregion
    //#region Info Colors
        $errorLight: #ef5350;
        $errorMain: #d32f2f;
        $errorDark: #c62828;
        $errorContrastText: #f2f2f2;

        $successLight: #4caf50;
        $successMain: #2e7d32;
        $successDark: #1b5e20;
        $successContrastText: #f2f2f2;

        $warningLight: #ff9800;
        $warningMain: #ed6c02;
        $warningDark: #e65100;
        $warningContrastText: #f2f2f2;

        $infoLight: #03a9f4;
        $infoMain: #0288d1;
        $infoDark: #01579b;
        $infoContrastText: #f2f2f2;
    //#endregion

    $dividerDark: #e6e6e6;
    $dividerLight:  #e6e6e6;

    $shadowDark: #000;
    $shadowLight: #777;

//#endregion

//#region Fonts

    $fontFamily: 'Mona';
    $fontSize: 14px;
    $fontWeightBlack: 700;
    $fontWeightBold: 600;
    $fontWeightSemiBold: 500;
    $fontWeightMedium: 400;
    $fontWeightRegular: 300;
    $fontWeightLight: 200;
    $fontWeightThin: 100;

    $textPrimary: #3c4043;
    $textSecondary: #7c8591;
    $textDisabled: #b3b3b3;
    $textLight: #e2e2e2;
    $textLightest: #f2f2f2;

//#endregion

//#region Breakpoints
$breakpointsXs: 0px;
$breakpointsSm: 600px;
$breakpointsMd: 900px;
$breakpointsLg: 1200px;
$breakpointsXl: 1536px;
//#endregion

//#region Animations
    $transitionSlow: 0.3s;
    $transitionMed: 0.2s;
    $transitionFast: 0.1s;
//#endregion

//#region Layout
$spacing: 5px;
$padding: 5px;

$borderRadius: 5px;
//#endregion

//#region Extensions
    .wrapped {

        @media (min-width: $breakpointsXs) and (max-width: ($breakpointsSm - 1)) { padding-left: $spacing * 4; padding-right: $spacing * 4; }
        @media (min-width: $breakpointsSm) and (max-width: ($breakpointsMd - 1)) { padding-left: $spacing * 6; padding-right: $spacing * 6; }
        @media (min-width: $breakpointsMd) and (max-width: ($breakpointsLg - 1)) { padding-left: $spacing * 8; padding-right: $spacing * 8; }
        @media (min-width: $breakpointsLg) and (max-width: ($breakpointsXl - 1)) { padding-left: $spacing * 10; padding-right: $spacing * 10; }
        @media (min-width: $breakpointsXl) { padding-left: calc((100dvw - 1436px) / 2); padding-right: calc((100dvw - 1436px) / 2); }
    }
//#endregion